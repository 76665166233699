<template>
  <div class="admin-menu-posts">
    <div class="admin-menu-items">
      <div class="admin-menu-items-content">
        <UserAsideMenu :_view="_view" @filter="$emit('filter')" :modal="modal" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["_view", "modal"],
  components: {
    UserAsideMenu: () => import("../user/UserAsideMenu.vue"),
  },
};
</script>
